// Colors
$theme: #3F51B5;
$themeLight: #F0F1FF;

// Transitions
$bc3s: background-color .3s ease;
$c3s: color .3s ease;
$o3s: opacity .3s ease;
$v3s: visibility .3s ease;

body,
html {
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
}

body {
  font-family: 'Roboto', 'Noto', sans-serif;
  font-size: 0.85em;
}

body > div#root {
  height: 100%;
}

h1,
h2,
h3 {
  color: rgb(48, 63, 159);
}

h2 {
  font-size: 22px;
}

a:link {
  color: #7d7d7d;
}

a:visited {
  color: #303f9f;
}

a:hover {
  text-decoration: none;
}

.green-text {
  color: #558b2f;
}

.gray-text {
  color: #bdbdbd;
}

.red-text {
  color: rgb(244, 67, 54);
}

.protocols-container {
  margin: 15px 10px 0 0;
  max-width: 1200px;
}

.protocols-box {

}

.no-protocols-text {
  margin: 10px 0;
  width: 100%;
}

.protocol-name-container {
  margin: 25px 0;
}

.protocol-name {
  margin: 0 0 0 10px;
}

.protocol-denial-textarea {
  border: 1px solid #eee;
  box-sizing: border-box;
  /* color: #f44336; */
  color: #ce281b;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 16px 0;
  outline-color: #f44336;
  padding: 16px 12px;
  resize: none;
  width: 100%;
}

.protocol-status {
  margin: 0 0 16px;
}

.protocol-denial-reason {
  background-color: #ffdbd8;
  box-sizing: border-box;
  color: #333;
  /* color: #f44336; */
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin-bottom: 20px;
  padding: 16px 12px;
  width: 100%;
}

.protocol-denial-error {
  margin-top: 0;
}

.special-edit-stripe {
  background-color: rgb(48, 63, 159);
  height: 29px;
  left: 0;
  position: absolute;
  top: 0;
  width: 7px;
}

.green-button {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
  color: #fff !important;

  &:hover {
    background-color: #53b757 !important;
    border-color: #53b757 !important;
  }

  &:active {
    background-color: #37843b !important;
    border-color: #37843b !important;
  }
}

.theme-color {
  color: $theme !important;
}

.green-button:disabled {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.ant-form-item-explain-error {
  font-size: 12px;
  margin-top: -8px;
}

.ant-table-thead th {
  background-color: rgb(219, 238, 218) !important;
  color: rgb(0, 48, 99) !important;
  font-size: 13.6px !important;
  font-weight: 700 !important;
  padding: 10px 5px !important;
}

.ant-table-row {
  transition: none !important;

  &:hover {
    filter: brightness(0.95);
  }
}

.ant-table-row td {
  // customized components from 'virtualizedtableforantd4'
  // disable borders for some reason, so we manually set them here
  border-right: 1px solid #f0f0f0 !important;
  border-bottom: 1px solid #f0f0f0 !important;
  font-size: 13.6px;
  padding: 3px 5px !important;
  transition: none !important;
}

.table-row-green td {
  background-color: #e1ffe9 !important;
}

.table-row-blue td {
  background-color: #cfe9ff !important;
}

.table-row-grey td {
  background-color: #ddd !important;
}

.table-row-faded {
  opacity: .4;
}

.table-row-orange td {
  background-color: #fff0e3 !important;
}

.table-row-red td {
  background-color: #ffe1e1 !important;
}

.table-row-editing td {
  background-color: $theme !important;
  color: #fff !important;
}

.table-row-highlight {
  filter: brightness(0.85) !important;

  td {
    background: #fafafa;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-table-filter-trigger {
  margin: 0 !important;
}

.form-item {
  margin: 0 !important;
}

.row, .row2, .row3, .row4, .row5, .nested-row2 {
  display: flex;
  flex-direction: row;
}

.row2, .row3, .row4, .row5 {
  justify-content: space-between;
  margin: 0 -4px;

  > div {
    padding: 0 4px !important;
  }
}

.nested-row2 {
  > div {
    padding: 0 4px !important;
  }

  > div:first-child {
    padding-left: 0 !important;
  }

  > div:last-child {
    padding-right: 0 !important;
  }
}

.row2, .nested-row2 {
  > div {
    width: 50% !important;
  }
}

.row3 {
  > div {
    width: 33.333% !important;
  }
}

.row4 {
  > div {
    width: 25% !important;
  }
}

.row5 {
  > div {
    width: 20% !important;
  }
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.roadtest-field-container {
  align-items: center;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;

  > div {
    margin-right: 8px !important;
    width: 250px;
  }
}
